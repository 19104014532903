var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableAdminStudent"},[_vm._m(0),_c('div',{staticClass:"text-muted mb-3"},[_vm._v("On this page you can view, search, edit and change password for students")]),_c('hr'),_c('div',{class:[!_vm.isMobile ? 'd-flex' : '', !_vm.isMobile ? 'flex-nowrap' : '']},[_c('a-form-item',[_c('a-input',{style:({
          width: _vm.isMobile ? '100%' : '225px',
          height: '40px'
        }),attrs:{"placeholder":"Search by NIS or Nama.."},on:{"keyup":_vm.searchStudent},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('a-select',{style:({
        'margin-left': !_vm.isMobile ? '20px' : '',
        width: _vm.isMobile ? '100%' : '100px',
        height: '48px'
      }),attrs:{"size":"large","placeholder":"Select Class","default-value":"VII-1"},on:{"change":_vm.handleSelect}},_vm._l((_vm.classes),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.tingkat)+"-"+_vm._s(item.simbol))])}),1),_c('div',{staticClass:"ml-auto"},[_c('a-button',{class:[_vm.isMobile ? 'mt-3' : '', _vm.isMobile ? 'mb-4' : ''],staticStyle:{"height":"40px"},attrs:{"type":"primary","icon":"plus","block":_vm.isMobile},on:{"click":function($event){$event.preventDefault();return _vm.toAddStudent($event)}}},[_vm._v("Add Student")])],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataTable,"loading":_vm.loading,"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"StatusPayment",fn:function(text){return _c('span',{},[(text)?_c('a-tag',{attrs:{"color":text === 'paid' ? 'green' : 'red'}},[_vm._v(_vm._s(text))]):_vm._e()],1)}},{key:"Actions",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"btn btn-outline-info mx-2",on:{"click":function($event){$event.preventDefault();return _vm.toDetailStudent(record.key)}}},[_vm._v("Detail")])])}}])},[_c('a-icon',{attrs:{"slot":"filterIcon","type":"search"},slot:"filterIcon"})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cui__utils__heading mb-0"},[_c('strong',[_vm._v("Rules")])])}]

export { render, staticRenderFns }