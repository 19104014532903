<template>
  <div class="tableAdminStudent">
    <div class="cui__utils__heading mb-0">
      <strong>Rules</strong>
    </div>
    <div
      class="text-muted mb-3"
    >On this page you can view, search, edit and change password for students</div>
    <hr />
    <div :class="[!isMobile ? 'd-flex' : '', !isMobile ? 'flex-nowrap' : '']">
      <!-- <div class="d-inline"> -->
      <a-form-item>
        <a-input
          v-model="search"
          @keyup="searchStudent"
          placeholder="Search by NIS or Nama.."
          :style="{
            width: isMobile ? '100%' : '225px',
            height: '40px'
          }"
        ></a-input>
      </a-form-item>
      <a-select
        size="large"
        placeholder="Select Class"
        :style="{
          'margin-left': !isMobile ? '20px' : '',
          width: isMobile ? '100%' : '100px',
          height: '48px'
        }"
        @change="handleSelect"
        default-value="VII-1"
      >
        <a-select-option
          v-for="item in classes"
          :key="item.id"
          :value="item.id"
        >{{item.tingkat}}-{{item.simbol}}</a-select-option>
      </a-select>
      <!-- </div> -->
      <div class="ml-auto">
        <a-button
          @click.prevent="toAddStudent"
          type="primary"
          icon="plus"
          :block="isMobile"
          :class="[isMobile ? 'mt-3' : '', isMobile ? 'mb-4' : '']"
          style="height:40px"
        >Add Student</a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="StatusPayment" slot-scope="text">
        <a-tag v-if="text" :color="text === 'paid' ? 'green' : 'red'">{{text}}</a-tag>
      </span>
      <a-icon slot="filterIcon" type="search" />
      <span slot="Actions" slot-scope="text, record">
        <a @click.prevent="toDetailStudent(record.key)" class="btn btn-outline-info mx-2">Detail</a>
      </span>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'NIS',
    dataIndex: 'Nis',
    // width: '15%',
    scopedSlots: {
      customRender: 'Nis',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Nama',
    dataIndex: 'Nama',
    sorter: true,
    // width: '32%',
    scopedSlots: { customRender: 'Nama' },
  },
  {
    title: 'Payment',
    dataIndex: 'StatusPayment',
    // sorter: true,
    // width: '15%',
    scopedSlots: { customRender: 'StatusPayment' },
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    // width: '20%',
    scopedSlots: { customRender: 'Actions' },
  },
]
export default {
  data() {
    return {
      search: '',
      columns,
      dataTable: [],
      pagination: {},
      classes: [],
      selectedClass: '',
      loading: false,
    }
  },
  methods: {
    toAddStudent() {
      this.$router.push({ name: 'Add Student Admin' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Student Admin'],
      })
    },
    toDetailStudent(id) {
      this.$router.push({ name: 'Detail Student Admin', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Student Admin'],
      })
    },
    searchStudent() {
      if (this.search.length > 2) {
        this.fetchData({
          id_kelas: this.selectedClass,
          search: this.search,
        })
      } else {
        this.fetchData({
          id_kelas: this.selectedClass,
          search: this.search,
        })
      }
    },
    handleSelect(value) {
      this.fetchData({
        id_kelas: value,
      })
      this.selectedClass = value
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchData({
        page: pagination.current,
        id_kelas: this.selectedClass,
        order: order,
      })
    },
    fetchData(params = { page: 1, id_kelas: 1, order: 'ASC', search: '' }) {
      this.loading = true
      this.$store.dispatch('admin/FETCH_STUDENTS', { page: params.page, kelasId: params.id_kelas, order: params.order, search: params.search })
        .then(data => {
          const pagination = { ...this.pagination }
          pagination.total = data.total
          this.loading = false
          this.dataTable = data.murid.map(row => {
            return {
              key: row.id,
              Nis: row.NIS,
              Nama: row.nama,
              StatusPayment: row.status_spp,
            }
          })
          this.pagination = pagination
        })
    },
    fetchDataKelas() {
      this.$store.dispatch('admin/FETCH_CLASS')
        .then(data => {
          this.classes = data
        })
    },
  },
  created() {
    this.fetchData()
    this.fetchDataKelas()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.tableAdminStudent {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }
}
</style>
