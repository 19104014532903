<template>
  <div>
    <a-row type="flex" align="bottom">
      <a-col :span="21">
        <h1>Students</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <TableStudent />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import TableStudent from '@/components/app/Admin/TableStudent'
export default {
  data() {
    return {
    }
  },
  components: {
    TableStudent,
  },
}
</script>

<style>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}
</style>
